.confirm-input {
    border: 1px solid #ccc;
}

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

// .table {
//     max-height: 500px;
//     overflow-y: scroll;
// }