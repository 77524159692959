.submit-btn {
    border: none;
    outline: none;
    min-width: 100px;
    cursor: pointer;
    outline: none;
    border-radius: 10px !important;
  }
  
  .submit-btn:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
    border-radius: 10px !important;
  }
  
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }