    [cmdk-root] {
      max-width: 640px;
      width: 100%;
      padding: 8px;
      background: #ffffff;
      border-radius: 12px;
      overflow: hidden;
      font-family: var(--font-sans);
      border: 1px solid var(--gray6);
      box-shadow: var(--cmdk-shadow);
      transition: transform 100ms ease;
  
      .dark & {
        background: rgba(22, 22, 22, 0.7);
      }
    }
  
    [cmdk-input] {
      font-family: var(--font-sans);
      border: none;
      width: 100%;
      font-size: 17px;
      padding: 8px 8px 16px 8px;
      outline: none;
      background: var(--bg);
      color: var(--gray12);
      border-bottom: 1px solid var(--gray6);
      margin-bottom: 16px;
      border-radius: 0;
  
      &::placeholder {
        color: var(--gray9);
      }
    }
  
    [cmdk-vercel-badge] {
      height: 20px;
      background: var(--grayA3);
      display: inline-flex;
      align-items: center;
      padding: 0 8px;
      font-size: 12px;
      color: var(--grayA11);
      border-radius: 4px;
      margin: 4px 0 4px 4px;
      user-select: none;
      text-transform: capitalize;
      font-weight: 500;
    }
  
    [cmdk-item] {
      content-visibility: auto;
  
      cursor: pointer;
      height: 48px;
      border-radius: 8px;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 16px;
      color: var(--gray11);
      user-select: none;
      will-change: background, color;
      transition: all 150ms ease;
      transition-property: none;
  
      &[data-selected='true'] {
        background: var(--grayA3);
        color: var(--gray12);
      }
  
      &[data-disabled='true'] {
        color: var(--gray8);
        cursor: not-allowed;
      }
  
      &:active {
        transition-property: background;
        background: var(--gray4);
      }
  
      & + [cmdk-item] {
        margin-top: 4px;
      }
  
      svg {
        width: 18px;
        height: 18px;
      }
    }
  
    [cmdk-list] {
      height: min(330px, calc(var(--cmdk-list-height)));
      max-height: 400px;
      overflow: auto;
      overscroll-behavior: contain;
      transition: 100ms ease;
      transition-property: height;
    }
  
    [cmdk-vercel-shortcuts] {
      display: flex;
      margin-left: auto;
      gap: 8px;
  
      kbd {
        font-family: var(--font-sans);
        font-size: 12px;
        min-width: 20px;
        padding: 4px;
        height: 20px;
        border-radius: 4px;
        color: var(--gray11);
        background: var(--gray4);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
      }
    }
  
    [cmdk-separator] {
      height: 1px;
      width: 100%;
      background: var(--gray5);
      margin: 4px 0;
    }
  
    *:not([hidden]) + [cmdk-group] {
      margin-top: 8px;
    }
  
    [cmdk-group-heading] {
      user-select: none;
      font-size: 12px;
      color: var(--gray11);
      padding: 0 8px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
  
    [cmdk-empty] {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      white-space: pre-wrap;
      color: var(--gray11);
    }


  [cmdk-overlay] {
      z-index: 1;
      background-color: hsl(0 0% 0% / 0.439);
      position: fixed;
      inset: 0;
      animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  [cmdk-dialog] {
    z-index: 1;
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-height: 85vh;
    max-width: 640px;
    padding: 25px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  