.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  
  .alert h4 {
    margin-top: 0;
    color: inherit;
    font-weight: bold;
  }
  
  .alert .alert-link {
    font-weight: bold;
  }
  
  .alert > p,
  .alert > ul {
    margin-bottom: 0;
  }
  
  .alert > p + p {
    margin-top: 5px;
  }
  
  .alert-dismissable,
  .alert-dismissible {
    padding-right: 35px;
  }
  
  .alert-dismissable .close,
  .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
  
  .alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }
  
  .alert-success hr {
    border-top-color: #c9e2b3;
  }
  
  .alert-success .alert-link {
    color: #2b542c;
  }
  
  .alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
  }
  

  .alert-info .alert-link {
    color: #245269;
  }
  
  .alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
  }
  
  .alert-warning hr {
    border-top-color: #f7e1b5;
  }
  
  .alert-warning .alert-link {
    color: #66512c;
  }
  
  .alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  }
  
  .alert-danger hr {
    border-top-color: #e4b9c0;
  }
  
  .alert-danger .alert-link {
    color: #843534;
  }