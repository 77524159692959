.switch-language-dropdown-wrapper {
    width: 40px;
    cursor: pointer;
}
.list-languages-dropdown {
    min-width: 150px;
    margin-top: 10px;
    &__item {
        cursor: pointer;
        background: white;
        padding: 10px;
        display: flex;
        align-items: center;

        &:hover {
            background: #f5f5f5;
        }

        &-icon {
            width: 40px;
            margin-right: 10px;
        }

        &-label {
            font-size: 14px;
            font-weight: 500;
            color: #000;
            
        }
    }

    &__item:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
    }
}