.confirm-btn {
    margin-left: 10px;
}
.RadioGroupRoot {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.RadioGroupItem {
    padding: 10px;
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    
}

.RadioGroupItem:hover {
    background-color: #f5f5f5;
}

.RadioGroupItem:focus {
    box-shadow: 0 0 0 2px black;
}

.RadioGroupIndicator {
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.Label {
    width: 100%;
    cursor: pointer;
    font-size: 15px;
    align-items: center;
    display: flex;
    input {
        margin-right: 15px;
    }
}